html,
body {
  overflow: hidden;
  background: #009688;
  color: white;
}

.notif__container {
  z-index: 999999;
}

.control.has-icons-left .icon.is-clickable,
.control.has-icons-right .icon.is-clickable {
  pointer-events: all;
  cursor: pointer;
  color: #4a4a4a;
}

.absolute-fill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999;
}

.auto-scroll {
  overflow: auto;
}

.always-scroll {
  overflow-y: scroll;
}

.main-background {
  background: #009688;
  color: white;
}

.dark-background {
  background: #006355;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  font-size: 1.5em;
}

h1 {
  font-size: 3.5em;
}

h2 {
  font-size: 3.2em;
}

h3 {
  font-size: 2.9em;
}

h4 {
  font-size: 2.6em;
}

h5 {
  font-size: 2.3em;
}

h6 {
  font-size: 2em;
}

#root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.has-small-padding {
  padding: 0.5em;
}

.has-small-margin {
  margin: 0.5em;
}

.panel-block.is-big {
  font-size: 1.8em;
}

.panel-block.is-big .panel-icon {
  font-size: 1em;
}

.is-flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.is-flex-vertical {
  flex-direction: column;
}

.is-relative {
  position: relative;
}

.is-absolute {
  position: absolute;
}

.loader-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ddd;
  color: black;
}

.coupon-view {
   display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2%;
}

.coupon-card {
  width: 600px;
}
.is-expanded-iframe {
  height: 100%;
  width: 100%;
  max-width: 90vw;
}

.loader {
  border: 16px solid #666; /* Light grey */
  border-top: 16px solid #009688; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

.flex-justify-end {
  justify-content: flex-end;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
}

.flex-expand {
  flex: 1;
}

.nowrap {
  white-space:nowrap;
}

.back-button {
  font-size: 0.8em;
}
/**
 * Like, basically PERFECT scrollbars
 */

/* 
   It's pure CSS. 
   Since a quick google search will confirm people going crazy about Mac OS Lion scrollbars...
   this has no fade-out effect.
  
   In Mac OS Lion, the lowest common denominator is always showing scrollbars by a setting.
   So, this fits that lowest common denominator.
   Facebook, lifehacker, and Google have all basically taken this approach. Of course Google uses incredibly ugly square scrollbars, but so be it.
   Also, in regards to the fade in/out effect, this may just be one reason why soo many people outraged (and still hide) the ticker.
   Ending note: I recommend this for non-lion users. As a lion user, I prefer my fading out scrollbars. If you sniff a UA of mac os lion, don't add this css.
*/

/* Turn on custom 8px wide scrollbar */
::-webkit-scrollbar {
  width: 1em; /* 1px wider than Lion. */
  /* This is more usable for users trying to click it. */
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 5em;
}
/* hover effect for both scrollbar area, and scrollbar 'thumb' */
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}

/* The scrollbar 'thumb' ...that marque oval shape in a scrollbar */
::-webkit-scrollbar-thumb:vertical {
  /* This is the EXACT color of Mac OS scrollbars. 
     Yes, I pulled out digital color meter */
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 5em;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61); /* Some darker color when you click it */
  -webkit-border-radius: 5em;
}

.no-overflow {
  overflow: hidden;
}

.table-wrapper{
  overflow-x: auto;
}


/*Mobile Query*/
@media only screen and (max-width:768px) {
  .panel-block.is-big {
    font-size: 1em;
  }
  .media {
    flex-direction: column;
  }
  
  .media-content .subtitle {
    padding-top: 0.5em;
 
  }
  .media-content .columns  {
    padding-top: 0.5em;
 
  }
  .media-right {
    padding-top: 0.5em;
  }
  .inline{
    display: inline;
    margin-left: 0.5em;
  }
   
  a.button{
width: 
100%;
margin-top: 0.5em;
  } 

  th {
    font-size:0.63em;
  }

  td {
    font-size: 0.63em;
    overflow-x: auto;
  }
  td .button.is-medium{
    font-size: 1em;
  }
/*
  .control .button{
    justify-content: left;
  }*/
  
  /* color customizado boton */ 
  /*
  .action-button{
    background-color:#009688;   
    border-color: transparent;
    color: #fff;
  }

  .action-button:hover{
   
    border-color: transparent;
    color: #fff;
  }


  .action-button:focus{
      
    border-color: transparent;
    color: #fff;
  }


  .action-button:active{
      
    border-color: transparent;
    color: #fff;
  }
*/
  /*   ****   */ 
  
  .navbar-burger:hover{
    background-color: transparent;
  }

  a.navbar-burger:hover{
    color: #3273dc;
  }

  .td-hidden-mobile{
    display: none;
  }
  /* Force table to not be like tables anymore */
	#responsive-table table, 
	#responsive-table thead, 
	#responsive-table tbody, 
	#responsive-table th, 
	#responsive-table td, 
	#responsive-table tr { 
		display: block; 
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	#responsive-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	#responsive-table tr { border: 1px solid #ccc; }
 
	#responsive-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:left;
	}
 
	#responsive-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
 
	/*
	Label the data
	*/
  #responsive-table td:before { content: attr(data-title); }
  
  #responsive-table tr {
  margin-bottom:0.5em;

  }

  .hidden-mobile {
    position: absolute;
		top: -9999px;
		left: -9999px;
    }
  
   .navbar-brand a.navbar-burger{
      margin-left:1px;
  
    }

    .column.is-5{
      padding:0;

    }


    .column.is-5 .control{
      font-size:0.6em;

    }

    .name-wrap {
      
     width: 250px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     
    }
}